<template>
  <div>
    <div id="persona-header">
      <h1 class="main-title">Persona</h1>
      <el-button class="save-button" type="primary" :loading="saving" @click="saveChanges">Save</el-button>
    </div>
    <div id="persona-body">
      <div class="meta-prompt persona-body-item">
        <div class="meta-prompt-title persona-body-title">
          <span>Bot</span>
          <el-tooltip class="item" effect="dark" content="The prompt that defines the chat bot's persona."
            placement="right">
            <i class="el-icon-info" style="color: grey; margin-left: 5px"></i>
          </el-tooltip>
        </div>
        <el-select filterable v-model="selectedPersonaIndex" size="mini" style="width: 140px; margin-right: 5px">
          <el-option :key="`persona-option-${index}`" v-for="(persona, index) in modules.genai.personas" :value="index"
            :label="persona.name" style="display: flex; justify-content: space-between; align-items: center">
            <span style="float: left">{{ persona.name }}</span>
            <el-button type="text" icon="el-icon-delete" size="mini" circle
              @click="modules.genai.personas.splice(index, 1)" />
          </el-option>
        </el-select>
        <el-popover v-model="showNewPersonaPopover" placement="left" width="344">
          <el-input v-model="newPersonaName" autofocus @keyup.enter.native="create"
            @keyup.esc.native="showNewPersonaPopover = false">
            <template slot="prepend">Persona name</template>
            <el-button slot="append" icon="el-icon-check" @click="create" />
          </el-input>

          <!-- Add new content -->
          <el-tooltip slot="reference" class="item" placement="bottom" content="Add new persona">
            <el-button size="mini" icon="el-icon-plus" />
          </el-tooltip>
        </el-popover>
      </div>
      <div class="meta-prompt persona-body-item">
        <div class="meta-prompt-title persona-body-title">
          <span>Name</span>
        </div>
        <el-input size="small" type="text" placeholder="Please input" v-model="selectedPersona.name"
          @change="handleChangePersonaTagName" />
      </div>
      <div class="persona-body-item">
        <div class="meta-prompt-title persona-body-title">
          <span>Stream Responses</span>
          <el-tooltip class="item" effect="dark" content="Enable streaming response for this persona."
            placement="right">
            <i class="el-icon-info" style="color: grey; margin-left: 5px"></i>
          </el-tooltip>
          <el-switch v-model="selectedPersona.streaming_response" style="margin-left: 20px" />
        </div>
      </div>
      <div class="persona-body-item">
        <el-card>
          <div style="display: flex; justify-content: space-between; align-items: center;">
            <div>
              <span>Before action</span>
              <el-tooltip class="item" effect="dark" content="Action to be performed before the bot's response."
                placement="right">
                <i class="el-icon-info" style="color: grey; margin-left: 5px"></i>
              </el-tooltip>
              <el-card style="margin: 20px auto;width: 500px">
                <EventNodeSelector :value="beforeBotAction" />
                <!-- @save-side-effect="$emit('save-side-effect')"  -->
              </el-card>
            </div>

            <div>
              <span>After action</span>
              <el-tooltip class="item" effect="dark" content="Action to be performed after the bot's response."
                placement="right">
                <i class="el-icon-info" style="color: grey; margin-left: 5px"></i>
              </el-tooltip>
              <el-card style="margin: 20px auto; width: 500px">
                <EventNodeSelector :value="afterBotAction" />
              </el-card>
              <span>Ignore bot response</span>
                <el-tooltip class="item" effect="dark" content="Ignore the bot's response render on after action avaiable."
                  placement="right">
                  <i class="el-icon-info" style="color: grey; margin-left: 5px"></i>
                </el-tooltip>
                <el-switch v-model="selectedPersona.ignoreChatOnAfterBot" style="margin-left: 20px" />
            </div>
          </div>
        </el-card>

      </div>
      <div class="meta-prompt persona-body-item">
        <div class="meta-prompt-title persona-body-title">
          <span>Prompt</span>
          <el-tooltip class="item" effect="dark" content="The prompt that defines the chat bot's persona."
            placement="right">
            <i class="el-icon-info" style="color: grey; margin-left: 5px"></i>
          </el-tooltip>
        </div>
        <el-input type="textarea" :rows="5" placeholder="Please input" v-model="selectedPersona.prompt" />
      </div>

      <div style="margin-top: 15px" :key="selectedPersonaIndex">
        <div v-if="document_tags.length > 0">
          <div>Category</div>
          <div style="margin-top: 10px" v-for="(item, index) in document_tags" :key="index">
            <el-checkbox :label="item.name" @change="handleTagChange(item.name, $event)"
              :checked="isPersonaTagsContain(item.name)"></el-checkbox>
            <div style="margin-top: 10px" v-if="isSelectedTagContains(item.name)">
              <el-select placeholder="Select tags..." multiple="" v-model="selectedTags[item.name]">
                <el-option v-for="(tag, index) in item.tags" :key="index" :label="tag" :value="tag"></el-option>
              </el-select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DEFAULT_PERSONA_PROMPT, PERSONA, DEFAULT_PERSONA_NAME } from "../constants";
import EventNodeSelector from "@/components/EventNodeSelector/Index";
import ContentNodeEditor from "@/components/ContentNodeEditor.vue";

export default {
  data() {
    return {
      saving: false,
      selectedPersonaIndex: 0,
      newPersonaName: "",
      showNewPersonaPopover: false,
      selectedTags: {},
      selectedPersona: {},
      beforeBotAction: {
        event: "",
        data: {},
        side_effects: Array,
      },
      afterBotAction: {
        event: "",
        data: {},
        side_effects: Array,
      },
    };
  },
  components: {
    EventNodeSelector,
    ContentNodeEditor
  },
  computed: {
    modules: {
      get() {
        return this.$store.state.modules;
      },
      set(value) {
        this.$store.state.modules = value;
      },
    },
    settings() {
      return this.$store.state.modules.genai;
    },
    document_tags() {
      return this.$store.state.modules.genai?.knowledge_document?.tags || [];
    },
  },
  methods: {
    saveChanges(event) {
      this.saving = true;
      this.selectedPersona.tags = this.selectedTags;
      this.$store
        .dispatch("SAVE_MODULES", {
          modules: this.modules,
        })
        .then(
          () => {
            this.saving = false;
            this.$message({
              type: "success",
              message: "Config Saved",
            });
          },
          () => {
            this.saving = false;
            this.$message({
              type: "error",
              message: "Error saving config",
            });
          }
        );
    },
    create() {
      if (this.newPersonaName) {
        this.modules.genai.personas.push({
          name: this.newPersonaName,
          prompt: DEFAULT_PERSONA_PROMPT,
          tags: {},
          beforeBotAction: {
            event: "",
            data: {},
            side_effects: Array,
          },
          afterBotAction: {
            event: "",
            data: {},
            side_effects: Array,
          },
        });
        this.selectedPersonaIndex = this.modules.genai.personas.length - 1;
        this.newPersonaName = "";
        this.showNewPersonaPopover = false;
        this.saveChanges();
      }
    },
    openUploadDialog() {
      this.uploadDialogVisible = true;
    },
    isSelectedTagContains(categoryName) {
      return _.has(this.selectedTags, categoryName);
    },
    isPersonaTagsContain(categoryName) {
      return _.has(this.selectedPersona.tags, categoryName);
    },
    handleTagChange(itemName, value) {
      if (value) {
        this.$set(this.selectedTags, itemName, []);
      } else {
        this.$delete(this.selectedTags, itemName);
      }
    },
    handleChangePersonaTagName(value) {
      this.selectedPersona.tags["persona"][0] = value;
    },
    selectPersona() {
      const persona = this.modules.genai.personas[this.selectedPersonaIndex];
      if (!persona.tags) {
        persona.tags = {};
      }
      this.selectedTags = persona.tags;

      if (!persona.beforeBotAction) {
        persona.beforeBotAction = {
          event: "",
          data: {},
          side_effects: Array,
        };
      }

      if (!persona.afterBotAction) {
        persona.afterBotAction = {
          event: "",
          data: {},
          side_effects: Array,
        };
      }

      this.selectedPersona = persona;
      this.beforeBotAction = persona.beforeBotAction;
      this.afterBotAction = persona.afterBotAction;
    },
  },
  created() {
    const personas = _.get(this.modules.genai, PERSONA, []);
    if (personas.length === 0) {
      this.$set(this.modules.genai, PERSONA, [
        {
          prompt: DEFAULT_PERSONA_PROMPT,
          name: DEFAULT_PERSONA_NAME,
          tags: {},
          streaming_response: true,
          ignoreChatOnAfterBot: true,
          beforeBotAction: {
            event: "",
            data: {},
            side_effects: Array,
          },
          afterBotAction: {
            event: "",
            data: {},
            side_effects: Array,
          },
        },
      ]);
      this.saveChanges();
    }
    this.selectPersona();
  },
  watch: {
    selectedPersonaIndex: function (value) {
      // If "pageData" ever changes, then we will console log its new value.
      this.selectPersona();
    },
  }
};
</script>

<style lang="scss">
#persona-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;

  .save-button {
    width: 240px;
  }
}

#persona-body {
  .persona-body-title {
    margin: 10px 0;
    font-size: 16px;
  }

  .persona-body-item {
    margin-top: 30px;
  }
}

.memory-bias-slider {
  width: 30%;
  display: flex;
  align-items: center;

  span {
    font-size: 14px;
  }
}
</style>
