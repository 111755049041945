<template>
  <div id="gen-ai-wrapper">
    <el-tabs v-model="activeTab">
      <el-tab-pane label="Configuration" name="configuration" v-if="false">
        <Configuration />
      </el-tab-pane>
      <el-tab-pane label="Persona" name="persona">
        <Persona />
      </el-tab-pane>
      <el-tab-pane label="Plan" name="plan">
        <Plan />
      </el-tab-pane>
      <el-tab-pane label="Plugins" name="plugins">
        <Plugins />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Configuration from "./Configuration/Index.vue";
import Persona from "./Persona/Index.vue";
import Plan from "./Plan/Index.vue";
import Plugins from "./Plugins/Index.vue";

export default {
  components: {
    Configuration,
    Persona,
    Plan,
    Plugins,
  },
  data() {
    return {
      activeTab: "persona",
    };
  },
  mounted() {
    // this.$store.dispatch("GENAI_GET_MODULES");
  },
};
</script>
