import Vue from "vue";
import { MutationTree } from "vuex/types";

import { GenAIState } from "./types";

const mutations: MutationTree<GenAIState> = {
  GENAI_SET_MODULES: (state, payload) => {
    Vue.set(state, "modules", payload);
  },
  GENAI_SET_SELECTED_MODULE_ID: (state, payload) => {
    // TODO
  },
  GENAI_SET_SELECTED_FUNCTION_ID: (state, payload) => {
    // TODO
  },
  SET_GENAI_MODULE: (state, payload) => {
    Vue.set(state.genaiModules, payload.id, payload);
  },
  GENAI_SET_DOCUMENTS: (state, payload) => {
    Vue.set(state, "genai_query_documents", payload);
  },
  GENAI_SET_QUERY_RESULT: (state, payload) => {
    Vue.set(state, "genai_query_result", payload);
  },
  GENAI_SET_UPLOADED_DOCUMENTS: (state, payload) => {
    Vue.set(state, "genai_uploaded_documents", payload);
  },
  SET_LOADING_DOCUMENTS: (state, payload) => {
    Vue.set(state, "isGettingDocuments", payload);
  },
  GENAI_SET_ARTICLES: (state, payload) => {
    Vue.set(state, "genai_articles", payload);
  },
};

export default mutations;
