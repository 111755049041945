import { GenAIState } from "./types";

const state: GenAIState = {
  modules: {},
  genaiModules: {
    1: {
      id: 1,
      name: "user",
      schema: `
// The following is a schema definition for user.

// User interface represents the structure of user data.
export interface User {
  email: string; // User's email address.
  full_name: string; // User's full name.
}

// API interface defines methods for interacting with user-related functionality.
export interface API {
  // register user
  userRegister(data: { email: string; full_name?: string }): User;
  // The userRegister method allows registering a new user with their email and optional full name.
}
      `.trim(),
    },
    2: {
      id: 2,
      name: "hospital",
      schema: `
// This schema outlines the structure for booking a doctor's appointment and interacting with related data.

// UnknownText represents an item type that doesn't match any other defined type.
export interface UnknownText {
  type: "unknown";
  text: string; // The text that couldn't be understood or categorized.
}

// Doctor interface defines the structure of doctor information.
export interface Doctor {
  id: number;    // Unique identifier for the doctor.
  name: string;  // Doctor's full name.
}

// Schedule interface represents the schedule of a doctor.
export interface Schedule {
  id: number;          // Unique identifier for the schedule.
  doctor: Doctor;      // Doctor associated with the schedule.
  patient: Patient;    // Patient scheduled for the appointment.
  day_name: string;    // Name of the day for the appointment.
  start_time: string;  // Start time of the appointment.
  end_time: string;    // End time of the appointment.
}

// Patient interface defines the structure of patient data.
export interface Patient {
  id: string;          // Unique identifier for the patient.
  full_name: string;   // Patient's full name.
  email: string;       // Patient's email address.
}

// Booking interface represents a doctor's appointment booking.
export interface Booking {
  id: number;            // Unique identifier for the booking.
  doctor_id: number;     // ID of the booked doctor.
  patient_id: string;    // ID of the patient making the booking.
  date: string;          // Date of the booked appointment.
}

// API interface defines methods for interacting with doctor booking functionality.
export interface API {
  // get doctor detail by name
  hospitalGetDoctor(query: { name: string }): Doctor;
  // The hospitalGetDoctor method fetches doctor details using their name.

  // find doctor schedules by doctor name
  hospitalFindDoctorSchedules(doctor: Doctor): Schedule[];
  // The hospitalFindDoctorSchedules method retrieves schedules for a given doctor.

  // create or update patient data
  hospitalCreatePatient(data: { full_name: string; email: string }): Patient;
  // The hospitalCreatePatient method allows creating or updating patient information.

  // book a doctor
  hospitalCreateBooking(data: {
    doctor: Doctor;
    patient: Patient;
    day_name: string;
  }): Booking;
  // The hospitalCreateBooking method facilitates booking an appointment with a doctor.
}
      `.trim(),
    },
    3: {
      id: 3,
      name: "calendly",
      schema: `
// This schema defines the structure for interacting with Calendly scheduling functionality.

// API interface defines methods for interacting with Calendly scheduling.
export interface API {
  // book a schedule by creating schedule url
  calendlyCreateSchedulingUrl(data: { email: string }): string;
  // The calendlyCreateSchedulingUrl method generates a scheduling URL based on the provided email.
}
      `.trim(),
    },
  },
  genaiFunctions: {
    1: {
      id: 1,
      moduleId: 1,
      name: "userRegister",
    },
    2: {
      id: 2,
      moduleId: 2,
      name: "hospitalGetDoctor",
    },
    3: {
      id: 3,
      moduleId: 2,
      name: "hospitalFindDoctorSchedules",
    },
    4: {
      id: 4,
      moduleId: 2,
      name: "hospitalCreatePatient",
    },
    5: {
      id: 5,
      moduleId: 2,
      name: "hospitalCreateBooking",
    },
    6: {
      id: 6,
      moduleId: 3,
      name: "calendlyCreateSchedulingUrl",
    },
  },
  selectedGenaiFunctionIds: [1, 2],
  sources: {
    1: {
      id: 1,
      type: "PDF",
      url: "https://krdev3.blob.core.windows.net/files/marketing.pdf",
      name: "marketing.pdf",
      documents: [
        {
          id: 1,
          text: "Sometimes people assume marketing is just about advertising or selling, but this is not the whole story. It is a key management discipline that ensures producers of goods and services can interpret consumer desires and match, or exceed them",
        },
        {
          id: 2,
          text: "The marketing process is central to the business performance of companies, both large and small, because it addresses the most important aspects of the market. It is about understanding the competitive marketplace and ensuring you can tap into key trends, reaching consumers with the right product at the right price, place and time.",
        },
      ],
    },
    2: {
      id: 2,
      type: "Website",
      url: "https://wikipedia.com/healthcare",
      name: "healthcare wikipedia",
      documents: [
        {
          id: 3,
          text: "Health care, or healthcare, is the improvement of health via the prevention, diagnosis, treatment, amelioration or cure of disease, illness, injury, and other physical and mental impairments in people. Health care is delivered by health professionals and allied health fields. Medicine, dentistry, pharmacy, midwifery, nursing, optometry, audiology, psychology, occupational therapy, physical therapy, athletic training, and other health professions all constitute health care. It includes work done in providing primary care, secondary care, and tertiary care, as well as in public health.",
        },
        {
          id: 4,
          text: "Access to healthcare may vary across countries, communities, and individuals, influenced by social and economic conditions as well as health policies. Providing health care services means the timely use of personal health services to achieve the best possible health outcomes.",
        },
        {
          id: 5,
          text: "Factors to consider in terms of healthcare access include financial limitations (such as insurance coverage), geographical and logistical barriers (such as additional transportation costs and the ability to take paid time off work to use such services), sociocultural expectations, and personal limitations (lack of ability to communicate with health care providers, poor health literacy, low income).[4] Limitations to health care services affects negatively the use of medical services, the efficacy of treatments, and overall outcome (well-being, mortality rates).",
        },
        {
          id: 6,
          text: "Health systems are organizations established to meet the health needs of targeted populations. According to the World Health Organization (WHO), a well-functioning healthcare system requires a financing mechanism, a well-trained and adequately paid workforce, reliable information on which to base decisions and policies, and well-maintained health facilities to deliver quality medicines and technologies. ",
        },
        {
          id: 7,
          text: "An efficient healthcare system can contribute to a significant part of a country's economy, development, and industrialization. Health care is conventionally regarded as an important determinant in promoting the general physical and mental health and well-being of people around the world.[5] An example of this was the worldwide eradication of smallpox in 1980, declared by the WHO, as the first disease in human history to be eliminated by deliberate healthcare interventions.",
        },
      ],
    },
  },
  genai_query_result: {},
  genai_query_documents: [],
  genai_uploaded_documents: [],
  genai_articles: [],
  isGettingDocuments: false,
};

export default state;
