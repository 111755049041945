<template>
  <div class="customer-journey">
    <el-row type="flex" justify="center" :gutter="20">
      <div
        v-for="(metric, idx) in metrics"
        class="metric"
        :key="'metric' + idx"
        @click="filterCustomer(metric)"
        :class="{
          'metric-danger': metric.name === 'High Priority Alert',
          'metric-warning': metric.name === 'Normal Priority Alert',
          'metric-info': metric.name === 'Low Priority Alert',
        }"
      >
        <div class="metric-value">{{ metric.value }}</div>
        <div class="metric-name">
          {{ metric.name === "All patients" ? "All Customers" : metric.name }}
        </div>
      </div>
    </el-row>
    <TabContactsHeader
      @refetch-contact="fetchContacts"
      @apply-filter="onApplyFilter"
      @apply-sort="onApplySort"
      @apply-filter-date="onApplyFilterDate"
      @refetch-data="fetchContacts"
    />
    <el-table v-loading="loadingData" :data="customerData" style="width: 100%">
      <el-table-column prop="name" label="Name"></el-table-column>
      <el-table-column prop="channel" label="Channel"></el-table-column>
      <el-table-column prop="primaryContact" label="Primary Contact"></el-table-column>
      <el-table-column prop="attributes.project_id" label="Project">
        <template slot-scope="props">
          <span>
            {{ projectName(props.row.attributes.project_id) }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="stage.name" label="Milestone"></el-table-column>
      <el-table-column prop="alert" label="Alert" width="250">
        <template slot-scope="props">
          <div
            v-if="props.row.attributes.alert_priority"
            class="alert-card"
            :class="{
              'alert-danger': props.row.attributes.alert_priority === 'High',
              'alert-warning': props.row.attributes.alert_priority === 'Normal',
              'alert-info': props.row.attributes.alert_priority === 'Low',
            }"
          >
            <div style="margin-bottom: 10px">
              <strong>{{ props.row.attributes.alert_type }}</strong>
            </div>
            <div style="margin-bottom: 10px">
              {{ props.row.attributes.alert_label }}:
              <strong>{{ props.row.attributes.alert_value }}</strong>
            </div>
            <div style="display: flex">
              <span style="width: 85px">Status: </span>
              <el-select
                v-model="props.row.attributes.alert_status"
                placeholder="Select"
                size="mini"
                @change="editAlert(props.row)"
              >
                <el-option v-for="item in status" :key="item" :label="item" :value="item">
                </el-option>
              </el-select>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="actions" label="Actions">
        <template slot-scope="props">
          <el-button
            icon="el-icon-info"
            circle
            type="info"
            size="mini"
            @click="viewCustomer(props.row)"
          >
          </el-button>
          <el-button
            icon="el-icon-edit"
            circle
            type="warning"
            size="mini"
            @click="editCustomer(props.row)"
          >
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      layout="prev, pager, next"
      :page-size="pageSize"
      :total="customerCount"
      @current-change="setPage"
      style="padding: 10px 0"
    >
    </el-pagination>

    <el-dialog title="Customer" :visible.sync="customerDialog">
      <div v-if="customer.id">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-steps :active="milestone" direction="vertical" :space="65">
              <el-step title="Start"></el-step>
              <el-step v-for="(item, index) in milestones" :title="item.name" :key="'ml' + item.id">
                <div slot="description" v-if="index < milestone">
                  <a href="#">View details</a>
                </div>
              </el-step>
              <el-step title="End"></el-step>
            </el-steps>
          </el-col>
          <el-col :span="12">
            <div style="margin-bottom: 15px">
              <label>Name</label>
              <br />
              <strong>{{ customer.name }}</strong>
            </div>
            <div style="margin-bottom: 15px">
              <label>Contact</label>
              <br />
              <strong>{{ customer.primaryContact }} ({{ customer.channel }})</strong>
            </div>
            <div style="margin-bottom: 15px">
              <label>Project</label>
              <br />
              <strong>{{ projectName(customer.attributes.project_id) }}</strong>
            </div>
            <div style="margin-bottom: 15px">
              <label>Notes</label>
              <br />
              <el-input
                type="textarea"
                :rows="7"
                placeholder="Please input"
                v-model="customer.attributes.notes"
              >
              </el-input>
              <div style="text-align: right; margin-top: 15px">
                <el-button size="small" type="success" @click="saveCustomer">Save</el-button>
              </div>
            </div>
            <div
              v-if="customer.attributes.alert_priority"
              class="alert-card"
              :class="{
                'alert-danger': customer.attributes.alert_priority === 'High',
                'alert-warning': customer.attributes.alert_priority === 'Normal',
                'alert-info': customer.attributes.alert_priority === 'Low',
              }"
            >
              <div style="margin-bottom: 10px">
                <strong>{{ customer.attributes.alert_type }}</strong>
              </div>
              <div style="margin-bottom: 10px">
                {{ customer.attributes.alert_label }}:
                <strong>{{ customer.attributes.alert_value }}</strong>
              </div>
              <div style="display: flex">
                <span style="width: 85px">Status: </span>
                <el-select
                  v-model="customer.attributes.alert_status"
                  placeholder="Select"
                  size="mini"
                  @change="editAlert(customer)"
                >
                  <el-option v-for="item in status" :key="item" :label="item" :value="item">
                  </el-option>
                </el-select>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="customerDialog = false">Close</el-button>
      </span>
    </el-dialog>

    <el-dialog title="Customer" :visible.sync="editDialog" width="40%">
      <div v-if="customer.id">
        <div style="margin-bottom: 20px">
          <label>First Name</label>
          <el-input v-model="customer.firstName"></el-input>
        </div>
        <div style="margin-bottom: 20px">
          <label>Last Name</label>
          <el-input v-model="customer.lastName"></el-input>
        </div>
        <div style="margin-bottom: 20px">
          <label>Channel</label>
          <el-select v-model="customer.channel" placeholder="Select" style="width: 100%">
            <el-option v-for="item in channels" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
        <div style="margin-bottom: 20px">
          <label>Primary Contact</label>
          <el-input v-model="customer.primaryContact"></el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialog = false">Cancel</el-button>
        <el-button type="primary" @click="saveCustomer">Save</el-button>
      </span>
    </el-dialog>

    <el-dialog title="Alert" :visible.sync="alertDialog" width="40%">
      <div v-if="customer.id">
        <div style="margin-bottom: 20px">
          <label>Name</label>
          <br />
          <strong>{{ customer.name }}</strong>
        </div>
        <div style="margin-bottom: 20px">
          <label>Alert Priority</label>
          <br />
          <el-tag
            size="small"
            effect="dark"
            type="danger"
            v-if="customer.attributes.alert_priority === 'High'"
          >
            {{ customer.attributes.alert_priority }}
          </el-tag>
          <el-tag
            size="small"
            type="danger"
            v-else-if="customer.attributes.alert_priority === 'Normal'"
          >
            {{ customer.attributes.alert_priority }}
          </el-tag>
          <el-tag size="small" type="warning" v-if="customer.attributes.alert_priority === 'Low'">
            {{ customer.attributes.alert_priority }}
          </el-tag>
        </div>
        <div style="margin-bottom: 20px">
          <label>Alert Type</label>
          <br />
          <strong>{{ customer.attributes.alert_type }}</strong>
        </div>
        <div style="margin-bottom: 20px">
          <label>Alert Status</label>
          <el-select
            v-model="customer.attributes.alert_status"
            placeholder="Select"
            style="width: 100%"
          >
            <el-option v-for="item in status" :key="item" :label="item" :value="item"> </el-option>
          </el-select>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="alertDialog = false">Cancel</el-button>
        <el-button type="primary" @click="saveCustomer">Save</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import gql from "graphql-tag";
import moment from "moment";
import _ from "lodash";

import TabContactsHeader from "@/views/Outreach/TabContacts/TabContactsHeader.vue";

export default {
  components: {
    TabContactsHeader,
  },
  data() {
    return {
      journeyList: [],
      customer: {
        id: 0,
      },
      filterDate: [],
      filterBoard: [],
      sortBoard: [],
      channels: ["Whatsapp", "Email", "SMS", "Webchat"],
      status: ["new", "reviewed"],
      page: 1,
      pageSize: 10,
      loadingData: false,
      customerDialog: false,
      editDialog: false,
      alertDialog: false,
    };
  },
  computed: {
    customerData() {
      const raw = this.$store.state.outreach.contacts.data || [];
      const data = _.map(raw, (item) => {
        const attributes = _.reduce(
          item.contactattributes,
          (result, value) => {
            result[value.name] = value.contactattributevalue.value;
            return result;
          },
          {}
        );
        return {
          ...item,
          attributes: attributes,
        };
      });

      return data;
    },
    customerCount() {
      return this.$store.state.outreach.contacts.count || 0;
    },
    metrics() {
      const raw = this.$store.state.outreach.metrics || [];
      const data = [];

      _.each(raw, (item) => {
        if (
          item.name === "All patients" ||
          item.name === "High Priority Alert" ||
          item.name === "Normal Priority Alert" ||
          item.name === "Low Priority Alert"
        ) {
          data.push(item);
        }
      });
      return data;
    },
    milestones() {
      const item = _.find(this.journeyList, {
        id: this.customer.attributes.project_id,
      });
      if (item) {
        return item.milestones;
      } else {
        return [];
      }
    },
    milestone() {
      const item = _.find(this.journeyList, {
        id: this.customer.attributes.project_id,
      });
      if (item) {
        const index = _.findIndex(item.milestones, {
          name: this.customer.stage.name,
        });
        return index + 1;
      } else {
        return -1;
      }
    },
    windowHeight() {
      return window.innerHeight;
    },
    tableHeight() {
      let height = window.innerHeight;
      return height - 425;
    },
  },
  mounted() {
    this.init();
    this.getJourney();
    this.filterDate = {
      startDate: moment().subtract(1, "years"),
      endDate: moment(),
    };
  },
  methods: {
    init() {
      this.fetchContacts();
      this.fetchMetrics();
    },
    projectName(id) {
      const item = _.find(this.journeyList, {
        id: id,
      });
      if (item) {
        return item.title;
      } else {
        return "";
      }
    },
    filterCustomer(metric) {
      if (metric.name === "All patients") {
        this.resetFilter();
      } else if (metric.name === "High Priority Alert") {
        this.highFilter();
      } else if (metric.name === "Normal Priority Alert") {
        this.normalFilter();
      } else if (metric.name === "Low Priority Alert") {
        this.lowFilter();
      }
    },
    resetFilter() {
      this.filterBoard = [];
      this.init();
    },
    highFilter() {
      this.filterBoard = [
        {
          comparison: "is",
          value: "High",
          valueArray: [],
          variable: "alert_priority",
        },
        {
          comparison: "is",
          value: "new",
          valueArray: [],
          variable: "alert_status",
        },
      ];
      this.init();
    },
    normalFilter() {
      this.filterBoard = [
        {
          comparison: "is",
          value: "Normal",
          valueArray: [],
          variable: "alert_priority",
        },
        {
          comparison: "is",
          value: "new",
          valueArray: [],
          variable: "alert_status",
        },
      ];
      this.init();
    },
    lowFilter() {
      this.filterBoard = [
        {
          comparison: "is",
          value: "Low",
          valueArray: [],
          variable: "alert_priority",
        },
        {
          comparison: "is",
          value: "new",
          valueArray: [],
          variable: "alert_status",
        },
      ];
      this.init();
    },
    onApplyFilter(filterList) {
      this.filterBoard = filterList;
      this.init();
    },
    onApplySort(sortAttribute) {
      this.sortBoard = sortAttribute;
      this.init();
    },
    onApplyFilterDate(filterDate) {
      const [startDate, endDate] = filterDate;
      this.filterDate = {
        startDate,
        endDate,
      };
      this.init();
    },
    setPage(val) {
      this.page = val;
      this.fetchContacts();
    },
    editCustomer(item) {
      this.customer = item;
      this.editDialog = true;
    },
    viewCustomer(item) {
      this.customer = item;
      this.customerDialog = true;
    },
    reviewAlert(item) {
      this.customer = item;
      this.alertDialog = true;
    },
    editAlert(item) {
      this.customer = item;
      this.$confirm("Are you sure you want to change the status of this alert?", "Confirmation", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          this.saveCustomer();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async saveCustomer() {
      const attributes = _.map(this.customer.contactattributes, (item) => {
        return {
          name: item.name,
          value: this.customer.attributes[item.name] ?? "",
          contactId: this.customer.id,
          attributeId: item.id,
          id: item.contactattributevalue?.id || undefined,
        };
      });

      await this.$apollo.mutate({
        mutation: gql`
          mutation OutReachContactImport($contact: JSON, $attributes: [JSON]) {
            outReachContactAPI {
              updateOutReachContact(contact: $contact, attributes: $attributes)
            }
          }
        `,
        variables: {
          contact: this.customer,
          attributes,
        },
      });

      this.alertDialog = false;
      this.editDialog = false;

      this.init();
    },
    async fetchContacts() {
      this.loadingData = true;
      await this.$store.dispatch("FETCH_OUTREACH_CONTACTS", {
        take: this.pageSize,
        page: this.page,
        ...this.filterDate,
        filter: this.filterBoard,
        sort: this.sortBoard,
      });
      this.loadingData = false;
    },
    async fetchMetrics() {
      await this.$store.dispatch("FETCH_OUTREACH_METRICS");
    },
    async getJourney() {
      try {
        let response = await this.$apollo.query({
          query: gql`
            query {
              Bot {
                customerJourney
              }
            }
          `,
          fetchPolicy: "network-only",
        });

        var data = _.get(response, "data.Bot.customerJourney", {});
        this.journeyList = _.map(data, (item) => {
          return item;
        });
        this.loadingData = false;
      } catch (error) {
        this.$notify.error({
          title: "Error",
          position: "bottom-right",
          message: error.message,
        });
        this.journeyList = [];
      }
    },
  },
};
</script>

<style>
.alert-title .el-icon-search {
  font-weight: bold;
  color: #303133;
}
</style>
<style scoped>
.customer-journey {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.metric {
  padding: 20px;
  border: 1px solid #409eff;
  background-color: #d9ecff;
  height: 70px;
  width: 150px;
  cursor: pointer;
}

.metric-value {
  font-size: 32px;
}

.metric-name {
  color: grey;
}

.alert-card {
  padding: 20px;
}

.alert-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.alert-title span {
  font-size: 18px;
  font-weight: bold;
}

.metric-danger,
.alert-danger {
  background-color: #fde2e2;
  border: 1px solid #c45656;
}

.metric-warning,
.alert-warning {
  background-color: #f8e3c5;
  border: 1px solid #b88230;
}

.metric-info,
.alert-info {
  background-color: #fdf6ec;
  border: 1px solid #e6a23c;
}
</style>
